import { validators } from "vue-form-generator";
import i18n from "@/plugins/i18n";

export const schema = {
  fields: [
    {
      type: "input",
      inputType: "email",
      label: "Email",
      model: "email",
      required: true,
      placeholder: "Email",
      max: 255,
      validator: [
        validators.email.locale({
          textTooBig: i18n.t("userEmailMustBeLess{1}"),
          invalidEmail: i18n.t("userEmailIncorrect"),
        }),
      ]
    },
    {
      type: "input",
      inputType: "text",
      label: "Credentials",
      model: "credentials",
      required: true,
      placeholder: "Credentials",
      max: 255,
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("credentialsRequired"),
          textTooBig: i18n.t("credentialsMustBeLess{1}")
        }),
      ]
    },
    {
      type: "select",
      label: "status",
      model: "status",
      required: true,
      selectOptions: {
        noneSelectedText: i18n.t("chooseStatus"),
      },
      values: [
        { name: i18n.t("Active"), id: 1 },
        { name: i18n.t("Hidden"), id: 0 },
      ],
    },
  ]
};
