import { actions } from "../../../components/grid-views/standart-actions-without-view";

export const columns = [
  {
    label: 'Email',
    field: 'email',
    width: '35%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'Credentials',
    field: 'credentials',
    width: '48%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'Groups',
    field: 'groups',
    width: '10%',
    sortable: false,
    formatFn: (value) => value.length,
  },
  {
    label: '',
    field: 'actions',
    width: '7%',
    sortable: false,
    actions: [
      ...actions,
    ]
  },
]
